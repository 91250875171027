<template lang="pug">
.confirm
  .confirm__title.container
      .confirm__title-text {{ title }}
  .confirm__checkboxes
    .confirm__checkbox(
      v-for="reason in allReasons"
      :key="reason.code"
    )
      label.label.container
        input(
          type="radio"
          name="radio"
          :value="reason"
          v-model="selectedReason"
        )
        .checkmark
        span {{reason.comment}}
    .confirm__textarea.container
      textarea(
        class="textarea"
        ref="anotherReason"
        @focus="focusAnotherReason"
        v-model="anotherReason.text"
        :maxlength="maxDeleteReasonCharsCount"
        placeholder="Опишите причину"
      )

  .confirm__footer.container
      .confirm__button.confirm__button--cancel(
        @click="cancelClick"
      ) Отмена
      .confirm__button.confirm__button--delete(
        @click="deleteClick"
        :class="buttonDeleteClass"
      ) Удалить аккаунт
</template>

<script lang="ts">
  type Reason = {
    code: string;
    comment: string;
    text: string;
  };

  export default defineNuxtComponent({
    name: 'ModalDeleteAccountConfirm',

    data: () => ({
      title: 'Пожалуйста, укажите причину удаления вашего аккаунта',
      selectedReason: null as Reason | null,
      maxDeleteReasonCharsCount: 255,

      // TODO
      // для причин есть эндпоинт, можно использовать его
      // https://app.clickup.com/t/263h789?comment=750466188
      anotherReason: {
        code: '000',
        comment: 'Другая',
        text: '',
      },
      reasons: [
        {
          code: '001',
          comment: 'Мне не нравятся статьи',
          text: '',
        },
        {
          code: '002',
          comment: 'Я не нахожу сайт полезным',
          text: '',
        },
        {
          code: '003',
          comment: 'Меня беспокоит конфиденциальность',
          text: '',
        },
        {
          code: '004',
          comment: 'У меня есть другой аккаунт',
          text: '',
        },
        {
          code: '005',
          comment: 'Это временно. Я еще вернусь',
          text: '',
        },
        {
          code: '006',
          comment: 'Я провожу на сайте слишком много времени',
          text: '',
        },
        {
          code: '007',
          comment: 'Неудобно пользоваться сайтом',
          text: '',
        },
      ],
    }),

    computed: {
      allReasons() {
        return [...this.reasons, this.anotherReason];
      },
      buttonDeleteClass() {
        const { selectedReason, anotherReason } = this;

        let isDisabled = true;

        if (selectedReason) {
          if (selectedReason.code === anotherReason.code) {
            isDisabled = !selectedReason.text.trim().length;
          } else {
            isDisabled = false;
          }
        }

        return {
          'confirm__button--disabled': isDisabled,
        };
      },
    },

    watch: {
      selectedReason: {
        handler(val) {
          if (val && val.code === this.anotherReason.code) {
            (this.$refs.anotherReason as HTMLElement).focus();
          }
        },
        deep: true,
      },
    },

    methods: {
      cancelClick(): void {
        this.$emit('cancel');
      },

      deleteClick(): void {
        if (
          this.selectedReason?.code !== '000' ||
          (this.selectedReason?.code === '000' && this.selectedReason.text.length !== 0)
        ) {
          this.$emit('delete', this.selectedReason);
        }
      },

      focusAnotherReason() {
        this.selectedReason = this.anotherReason;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .confirm {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    &__title {
      border-bottom: 1px solid #e7e7e7;

      &-text {
        width: 100%;
        @include fontH4AccentNormalText;
        padding: 16px 0;
        text-align: left;
      }
    }

    &__checkboxes {
      flex-grow: 1;
    }

    &__footer {
      padding: 24px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-top: 1px solid #e7e7e7;
      position: sticky;
      left: 0;
      bottom: 0;
      width: 100%;

      @include tablet {
        border: none;
        position: static;
        padding: 40px 0;
      }
    }

    &__button {
      $button: &;

      width: 138px;
      @include fontTextBtn;
      // font-weight: normal;
      padding: 8px 0px;
      text-align: center;
      border-radius: 2px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;

      @include tablet {
        width: 200px;
      }

      &--delete {
        background-color: #2962f9;
        color: white;
        border-color: #2962f9;

        @include hover {
          background-color: #264eb1;
          border-color: #264eb1;
        }

        &#{$button} {
          &--disabled {
            background-color: #d4d7de;
            border-color: #d4d7de;
            cursor: default;

            @include hover {
              background-color: #d4d7de;
              border-color: #d4d7de;
            }
          }
        }
      }

      &--cancel {
        background-color: white;
        color: #5c5c5c;
        border-color: #e7e7e7;

        @include hover {
          border-color: #5c5c5c;
        }
      }

      & + & {
        margin-left: 8px;

        @include tablet {
          margin-left: 16px;
        }
      }
    }
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    height: 48px;
    padding: 0 22px;
    font-size: 16px;
    line-height: 19px;

    @include hover {
      background: rgba(54, 112, 249, 0.15);
      input {
        & ~ .checkmark {
          border: 2px solid #3a74f6;
          &:before {
            display: block;
          }
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked {
        & ~ .checkmark {
          border: 2px solid #3a74f6;
          &:before {
            display: block;
          }
        }
        & ~ span {
          color: #3a74f6;
        }
      }
    }
  }

  .checkmark {
    width: 20px;
    height: 20px;
    border: 2px solid #969698;
    border-radius: 50%;
    position: relative;
    margin-right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &:before {
      content: '';
      display: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #3a74f6;
    }
  }

  .textarea {
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    padding: 12px;
    @include fontTextBtn;
    font-weight: normal;
    color: #4c4c4c;
    width: 100%;
    resize: none;
    outline: none;
    height: 80px;
    margin-bottom: 8px;

    &::placeholder {
      color: #777474;
    }

    &:focus {
      border-color: #3a74f6;
    }
  }
</style>
