<template lang="pug">
.modal(
  v-if="isShow"
  @click="onClick"
  ref="modal"
)
  .modal__container
    .modal__close(
      @click="closeModal"
    )
    .modal__content
      component(
        :is="component"
        @cancel="closeModal"
        @continue="onContinueDelete"
        @delete="onDeleteConfirm"
      )
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';
  import ModalDeleteAccountPrompt from '~/components/ModalDeleteAccount/ModalDeleteAccountPrompt/ModelDeleteAccountPrompt.vue';
  import ModalDeleteAccountConfirm from '~/components/ModalDeleteAccount/ModalDeleteAcountConfirm/ModalDeleteAcountConfirm.vue';
  import PageScroll from '~/libs/PageScroll';

  export default defineNuxtComponent({
    name: 'ModalDeleteAccount',

    components: {
      ModalDeleteAccountPrompt,
      ModalDeleteAccountConfirm,
    },

    props: {
      isShow: {
        type: Boolean,
        default: true,
      } as PropOptions<boolean>,
    },

    data: () => ({
      component: 'ModalDeleteAccountPrompt',
    }),

    watch: {
      isShow(value) {
        if (value) {
          PageScroll.disable();
        } else {
          PageScroll.enable();
        }
      },
    },

    methods: {
      closeModal(): void {
        this.$emit('close');
      },

      onContinueDelete(): void {
        this.component = 'ModalDeleteAccountConfirm';
      },

      onClick(event: Event): void {
        if (event.target === this.$refs.modal) {
          this.closeModal();
        }
      },

      onDeleteConfirm(reason: any): void {
        this.$emit('delete', reason);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 15;

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      @include closeMask;
      background-color: #969698;
      cursor: pointer;

      @include hover {
        background-color: #000;
      }
    }

    &__container {
      background-color: #fff;
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: hidden;
      position: relative;

      @include tablet {
        width: 640px;
        height: auto;
        border-radius: 8px;
        align-self: center;
      }
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include tablet {
        height: auto;
      }
    }
  }
</style>
