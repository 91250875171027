<template>
  <div class="profile">
    <ModalDeleteAccount
      :is-show="isShowModalDelete"
      @close="onCloseModal"
      @delete="onDeleteAccount"
    />

    <TopHeaderContainer v-show="isHeadShow" />

    <button
      v-show="!isHeadShow"
      class="profile__resume-button profile__resume-button--header"
      @click="backToProfile"
    >
      {{ backToProfileTitle }}
    </button>

    <NavMenuContainer class="main" />

    <PrimaryMenu
      v-show="isPrimaryMenuShow"
      :primary-nav-menu-items="primaryNavMenuItems"
      location="mobile"
      type="secondary"
    />

    <NavMenuBelowHeaderContainer />

    <slot id="main" />

    <TheFooterContainer class="profile__footer" />
  </div>
</template>

<script lang="ts">
  import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
  import TheFooterContainer from '~/containers/TheFooterContainer.amp.vue';
  import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
  import PrimaryMenu from '~/components/PrimaryMenu.vue';
  import NavMenuBelowHeaderContainer from '~/containers/NavMenuBelowHeaderContainer.vue';
  import { useMenusStore } from '~/store/menus';
  import { useUIStore } from '~/store/ui';
  import WindowBreakpoints from '~/utils/windowBreakpoints';
  import ModalDeleteAccount from '~/components/ModalDeleteAccount/ModalDeleteAccount.vue';

  import {
    leavePrivateChannel,
    pushBadge,
    pusherResetStore,
  } from '~/components/Pusher/websockets-functions';
  import { useIndexStore } from '~/store';

  export default defineNuxtComponent({
    name: 'ProfileLayout',
    components: {
      TopHeaderContainer,
      TheFooterContainer,
      NavMenuContainer,
      PrimaryMenu,
      NavMenuBelowHeaderContainer,
      ModalDeleteAccount,
    },

    data: () => ({
      breakpoints: null as null | WindowBreakpoints,
      breakpointHeadersExclude: ['mobile', 'tablet'],
      namesHeadersExclude: ['profile-settings', 'profile-edit', 'profile-favorites-edit'],
    }),

    computed: {
      isShowModalDelete(): boolean {
        return useUIStore().isModalDeleteAccountShow;
      },
      primaryNavMenuItems(): MenuItem[] {
        return useMenusStore().menus?.projects?.items ?? [];
      },
      isHeadShow(): boolean {
        return useUIStore().isHeaderShow;
      },
      isSidebarShow(): boolean {
        return this.isShowHeadByRoute && this.isShowHeadByBreakpoint;
      },
      isPrimaryMenuShow(): boolean {
        return useUIStore().isPrimaryMenuShow;
      },
      breakpointCurrent(): string | null {
        return this.breakpoints ? this.breakpoints.breakpointCurrent : null;
      },
      isShowHeadByBreakpoint(): boolean {
        return !this.breakpointHeadersExclude.includes(this.breakpointCurrent ?? '');
      },
      isShowHeadByRoute(): boolean {
        return !this.namesHeadersExclude.includes(this.routeName);
      },
      routeName(): string {
        return useRoute().name?.toString() ?? '';
      },
      backToProfileTitle(): string {
        const titlesByRouteName = {
          'profile-edit': 'Редактировать профиль',
          'profile-settings': 'Настройки',
          'profile-favorites-edit': 'Управление папками',
        } as { [key: string]: string };
        return titlesByRouteName[this.routeName] || '';
      },
    },

    watch: {
      breakpointCurrent() {
        this.checkHeadShow();
      },

      $route() {
        this.checkHeadShow();
      },
    },

    mounted() {
      this.breakpoints = new WindowBreakpoints();
    },

    unmounted() {
      if (this.breakpoints) {
        this.breakpoints.destroy();
      }
    },

    methods: {
      backToProfile(): void {
        const { href } = useRouter().resolve({
          name: 'profile-comments',
          params: useRoute().params,
          query: useRoute().query,
        });
        location.href = href;
      },
      checkHeadShow(): void {
        const uiStore = useUIStore();
        if (this.isShowHeadByRoute || this.isShowHeadByBreakpoint) {
          uiStore.showHeader();
          uiStore.showPrimaryMenu();
        } else {
          uiStore.hideHeader();
          uiStore.hidePrimaryMenu();
        }
      },

      onCloseModal(): void {
        useUIStore().hideModalDeleteAccount();
      },

      async onDeleteAccount(reason: { code: string; comment: string }): Promise<void> {
        try {
          const response = await useIndexStore().deleteUserAccount(reason);

          if (response.data.success) {
            this.logout();
          } else {
            throw new Error('Ошибка при удалении аккаунта');
          }
        } catch (error) {
          console.error(error);
          pushBadge({
            content: 'Произошла ошибка при удалении аккаунта, попробуйте позже',
            isError: true,
          });
        }
        useUIStore().hideModalDeleteAccount();
      },

      logout() {
        leavePrivateChannel();
        pusherResetStore();

        useIndexStore().logout();
      },
    },
  });
</script>

<style lang="scss">
  .profile {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      min-height: 464px;
    }

    .profile__footer {
      margin-bottom: 0;
    }

    &__resume-button {
      position: sticky;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 6;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: #ed5e42;
      user-select: none;
      @include fontTextBtn;
      color: white;
      font-weight: normal;
      padding: 12px 16px;

      &:after {
        content: '';
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        padding: 6px;
        @include arrowMask;
        background-color: white;
        box-sizing: content-box;
        z-index: 1;
      }
    }
  }
</style>
