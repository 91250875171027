<template lang="pug">
.prompt
  .prompt__image(
    v-if="imagePath"
    :style="promptImageStyle"
  )
  .prompt__title {{ title }}
  .prompt__content {{ text }}
  .prompt__footer
    .prompt__button.prompt__button--cancel(
      @click="cancelClick"
    ) Отмена
    .prompt__button.prompt__button--continue(
      @click="continueClick"
    ) Продолжить
</template>

<script lang="ts">
  import DeleteAccount from '~/components/ModalDeleteAccount/ModalDeleteAccountPrompt/images/delete-account.svg';

  export default defineNuxtComponent({
    name: 'ModalDeleteAccountPrompt',

    data: () => ({
      title: 'Вы действительно хотите удалить этот аккаунт?',
      text: 'Профиль на Лайфхакере будет удалён. Вы не сможете его восстановить.',
      imagePath: DeleteAccount,
    }),

    computed: {
      promptImageStyle(): any {
        return this.imagePath
          ? {
              background: `url('${this.imagePath}')`,
            }
          : null;
      },
    },

    methods: {
      cancelClick(): void {
        this.$emit('cancel');
      },

      continueClick(): void {
        this.$emit('continue');
      },
    },
  });
</script>

<style lang="scss" scoped>
  .prompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    @include tablet {
      padding: 48px;
    }

    &__image {
      width: 200px;
      height: 200px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    &__title {
      @include fontH4;
      margin-bottom: 8px;
      text-align: center;
    }

    &__content {
      @include fontNormalText;
      margin-bottom: 32px;
      text-align: center;

      @include tablet {
        margin-bottom: 40px;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &__button {
      min-width: 138px;
      width: 100%;
      @include fontTextBtn;
      font-weight: normal;
      padding: 8px 0px;
      text-align: center;
      border-radius: 2px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;

      @include tablet {
        width: 200px;
      }

      &--cancel {
        background-color: #2962f9;
        color: white;
        border-color: #2962f9;
      }

      &--continue {
        background-color: white;
        color: #5c5c5c;
        border-color: #e7e7e7;
      }

      & + & {
        margin-left: 8px;

        @include tablet {
          margin-left: 16px;
        }
      }
    }
  }
</style>
